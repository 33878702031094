import { createSlice } from "@reduxjs/toolkit";

const initialCertificateState = {
  data: [],
  loading: false,
  error: null,
};

const initialStatsState = {
  data: [],
  loading: false,
  error: null,
};

const initialUsersState = {
  data: [],
  loading: false,
  error: null,
};

const initialState = {
  data: [],
  loading: false,
  error: null,
  initialStatsState,
  initialUsersState,
  initialCertificateState,
};

const dashboardReportSlice = createSlice({
  name: "DashboardReport",
  initialState,
  reducers: {
    reportLoading(state) {
      state.loading = true;
      state.error = null;
    },
    reportError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setReportList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    usersStatsLoading(state) {
      state.initialStatsState.loading = true;
      state.initialStatsState.error = null;
    },
    usersStatsError(state, action) {
      state.initialStatsState.loading = false;
      state.initialStatsState.error = action.payload;
    },
    setUsersStatsList(state, action) {
      state.initialStatsState.loading = false;
      state.initialStatsState.data = action.payload;
    },
    usersLoading(state) {
      state.initialUsersState.loading = true;
      state.initialUsersState.error = null;
    },
    usersError(state, action) {
      state.initialUsersState.loading = false;
      state.initialUsersState.error = action.payload;
    },
    setUsersList(state, action) {
      state.initialUsersState.loading = false;
      state.initialUsersState.data = action.payload;
    },
    certificateLoading(state) {
      state.initialCertificateState.loading = true;
      state.initialCertificateState.error = null;
    },
    certificateError(state, action) {
      state.initialCertificateState.loading = false;
      state.initialCertificateState.error = action.payload;
    },
    setCertificateList(state, action) {
      state.initialCertificateState.loading = false;
      state.initialCertificateState.data = action.payload;
    },
  },
});

export const {
  reportLoading,
  reportError,
  setReportList,
  usersStatsLoading,
  usersStatsError,
  setUsersStatsList,
  usersLoading,
  usersError,
  setUsersList,
  certificateLoading,
  certificateError,
  setCertificateList,
} = dashboardReportSlice.actions;

export default dashboardReportSlice.reducer;
