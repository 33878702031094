import { Box, Typography } from "@mui/material";

export default function TitleBanner({ text }) {
  return (
    <Box sx={{ bgcolor: "#FAFAFA", borderRadius: "5px", p: "0.4em 2em" }}>
      <Typography variant="subtitle1" sx={{ color: "#081531" }}>
        {text}
      </Typography>
    </Box>
  );
}
