import {
  ticketsLoading,
  ticketsError,
  setTicketsList,
} from "../Slices/DashboardTickets";
import { dashboardTicketsService } from "../../Services/DashboardTickets";

export const dashboardTicketsAction =
  ({page, rowsPerPage,text}) => async (dispatch) => {
    dispatch(ticketsLoading());
    try {
      const response = await dashboardTicketsService({page, rowsPerPage,text});
      if (response?.error) {
        dispatch(ticketsError(response.error));
        return;
      } else {
        dispatch(setTicketsList(response?.data));
        return;
      }
    } catch (error) {
      dispatch(ticketsError(error.message));
      return;
    }
  };
