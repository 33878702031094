import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
import { useState } from "react";
import {
  deleteContentPageAction,
  deleteTicketAction,
} from "../../Store/Actions/Tickets";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeletePopupContentDialog({ data, open, close }) {
  const [loading, setLoading] = useState();
  const [formError, setFormError] = useState("");

  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      if (loading) return;
      setLoading(true);
      setFormError("");

      const response = await dispatch(
        deleteContentPageAction({ id: data?.id })
      );

      if (response?.status !== 200) {
        setFormError(response);
        return setLoading(false);
      }
      setLoading(false);

      return close();
    } catch (error) {
      setFormError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px",
          bgcolor: "#F4F5F7",
          maxHeight: "50%",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <img src="./images/dialogBg.png" alt="" style={{ width: "100%" }} />
        <img
          src="./images/dialogVectors.png"
          alt=""
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#FFF",
          }}
        >
          <span style={{ color: "#25AAE1" }}>Delete</span> Content
        </Typography>
      </Box>
      <DialogTitle>
        <Typography variant="h6" sx={{ color: "#202020", textAlign: "center" }}>
          Are you sure about deleting this content?
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
      >
        <Grid container spacing={2}>
          {formError && (
            <Grid item xs={12}>
              <FormHelperText
                sx={{
                  color: "red",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {formError}
              </FormHelperText>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "stretch",
                gap: "1em",
              }}
            >
              <Button
                variant="outlined"
                onClick={close}
                sx={{ p: "0.5em 3em" }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{ p: "0.5em 3em" }}
                onClick={handleDelete}
              >
                Delete Content
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
