import {
  addContentPageService,
  createContentPageService,
  createPackageTicketService,
  createSingleTicketService,
  deleteContentPageService,
  deleteTicketService,
  getContentPageService,
  updateContentPageService,
  updatePackageTicketService,
  updateSingleTicketService,
} from "../../Services/Tickets";
import {
  addTicket,
  deleteTicket,
  editTicket,
} from "../Slices/DashboardTickets";
import {
  ticketLoading,
  ticketError,
  contentLoading,
  setContentList,
  contentError,
  addContent,
  editContent,
  deleteContent,
  createContent,
} from "../Slices/Tickets";

export const createPackageTicketAction = (formData) => async (dispatch) => {
  dispatch(ticketLoading());
  try {
    const response = await createPackageTicketService(formData);

    await dispatch(addTicket(response?.data?.data?.reservation));

    return response;
  } catch (error) {
    dispatch(ticketError(error.response.data.error.message));
    return error.response.data.error.message;
  }
};

export const createSingleTicketAction = (formData) => async (dispatch) => {
  dispatch(ticketLoading());
  try {
    const response = await createSingleTicketService(formData);

    await dispatch(addTicket(response?.data?.data?.reservation));

    return response;
  } catch (error) {
    dispatch(ticketError(error.response.data.error.message));
    return error.response.data.error.message;
  }
};

export const updatePackageTicketAction = (formData) => async (dispatch) => {
  dispatch(ticketLoading());
  try {
    const response = await updatePackageTicketService(formData);

    await dispatch(editTicket(response?.data?.data?.reservation));

    return response;
  } catch (error) {
    dispatch(ticketError(error.response.data.error.message));
    return error.response.data.error.message;
  }
};

export const updateSingleTicketAction = (formData) => async (dispatch) => {
  dispatch(ticketLoading());
  try {
    const response = await updateSingleTicketService(formData);

    await dispatch(editTicket(response?.data?.data?.reservation));

    return response;
  } catch (error) {
    dispatch(ticketError(error.response.data.error.message));
    return error.response.data.error.message;
  }
};

export const deleteTicketAction =
  ({ reservationId }) =>
  async (dispatch) => {
    dispatch(ticketLoading());
    try {
      const response = await deleteTicketService({ reservationId });

      await dispatch(deleteTicket(response?.data?.data));

      return response;
    } catch (error) {
      dispatch(ticketError(error.response.data.error.message));
      return error.response.data.error.message;
    }
  };

export const createContentPageAction = (formData) => async (dispatch) => {
  dispatch(contentLoading());
  try {
    const response = await createContentPageService(formData);

    await dispatch(createContent(response?.data?.data));

    return response;
  } catch (error) {
    dispatch(contentError(error.response.data.error.message));
    return error.response.data.error.message;
  }
};

export const addContentPageAction = (content) => async (dispatch) => {
  dispatch(contentLoading());
  try {
    const response = await addContentPageService(content);

    await dispatch(addContent(response?.data?.data));

    return response;
  } catch (error) {
    dispatch(contentError(error.response.data.error.message));
    return error.response.data.error.message;
  }
};

export const getContentPageAction = () => async (dispatch) => {
  dispatch(contentLoading());
  try {
    const response = await getContentPageService();

    await dispatch(setContentList(response?.data?.data?.data));

    return response;
  } catch (error) {
    dispatch(contentError(error.response.data.error.message));
    return error.response.data.error.message;
  }
};

export const updateContentPageAction = (content) => async (dispatch) => {
  dispatch(contentLoading());
  try {
    const response = await updateContentPageService(content);

    await dispatch(editContent(response?.data?.data));

    return response;
  } catch (error) {
    dispatch(contentError(error.response.data.error.message));
    return error.response.data.error.message;
  }
};

export const deleteContentPageAction =
  ({ id }) =>
  async (dispatch) => {
    dispatch(contentLoading());
    try {
      const response = await deleteContentPageService({ id });

      await dispatch(deleteContent(response?.data?.data?.content));

      return response;
    } catch (error) {
      dispatch(contentError(error.response.data.error.message));
      return error.response.data.error.message;
    }
  };
