import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Box, Typography } from "@mui/material";

export default function BarChartComponent({ title, data }) {
  const locations = data || [];

  const barChartData = locations.map((loc, index) => ({
    value: loc.count,
    label: loc.location || `Location ${index + 1}`,
  }));

  return (
    <Box
      sx={{
        boxShadow: "0px 4px 18px rgba(216, 216, 216, 0.6)",
        width: "100%",
      }}
    >
      <Box sx={{ bgcolor: "#FAFAFA", p: "0.5em 1em", borderRadius: "5px" }}>
        <Typography variant="subtitle1" sx={{ color: "#081531" }}>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: "#FFF",
          p: "1em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          minWidth: "200px",
          minHeight: "150px",
        }}
      >
        <BarChart
          width={500}
          height={300}
          series={[
            {
              data: barChartData.map((item) => item.value),
              id: "uvId",
              stack: "total",
              color: "#6E6E6E",
            },
          ]}
          xAxis={[
            { data: barChartData.map((item) => item.label), scaleType: "band" },
          ]}
        />
      </Box>
    </Box>
  );
}
