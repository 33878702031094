import {
  reportLoading,
  reportError,
  setReportList,
  usersStatsLoading,
  usersStatsError,
  setUsersStatsList,
  usersLoading,
  usersError,
  setUsersList,
  certificateLoading,
  certificateError,
  setCertificateList,
} from "../Slices/DashboardReport";
import {
  dashboardReportService,
  sendCertificateService,
  usersListService,
  usersStatsService,
} from "../../Services/DashboardReport";

export const dashboardReportAction = () => async (dispatch) => {
  dispatch(reportLoading());
  try {
    const response = await dashboardReportService();
    if (response?.error) {
      dispatch(reportError(response.error));
      return;
    } else {
      dispatch(setReportList(response?.data));
      return;
    }
  } catch (error) {
    dispatch(reportError(error.message));
    return;
  }
};

export const usersStatsAction = () => async (dispatch) => {
  dispatch(usersStatsLoading());
  try {
    const response = await usersStatsService();
    if (response?.error) {
      dispatch(usersStatsError(response.error));
      return;
    } else {
      dispatch(setUsersStatsList(response?.data));
      return;
    }
  } catch (error) {
    dispatch(usersStatsError(error.message));
    return;
  }
};

export const usersListAction = () => async (dispatch) => {
  dispatch(usersLoading());
  try {
    const response = await usersListService();
    if (response?.error) {
      dispatch(usersError(response.error));
      return;
    } else {
      dispatch(setUsersList(response?.data));
      return;
    }
  } catch (error) {
    dispatch(usersError(error.message));
    return;
  }
};

export const sendCertificateAction = (formData) => async (dispatch) => {
  dispatch(certificateLoading());
  try {
    const response = await sendCertificateService(formData);
    if (response?.error) {
      dispatch(certificateError(response.error));
      return response;
    } else {
      dispatch(setCertificateList(response?.data));
      return response;
    }
  } catch (error) {
    dispatch(certificateError(error.message));
    return error;
  }
};
