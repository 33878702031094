import axios from "axios";
import { registrationEventId } from "./../Constants/index";

export const dashboardTicketsService = async ({ page, rowsPerPage, text }) => {
  const authToken = localStorage.getItem("authToken");
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/page/reservation`, {
      params: {
        registrationEventId: registrationEventId,
        limit: rowsPerPage,
        page: page,
        text,
      },
      headers: {
        Authorization: authToken,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Unable to fetch Tickets data: ${error.message}`);
    });
};
