import { Box, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Upload } from "@mui/icons-material";

export default function TicketCoverField({
  icon,
  label,
  onImageSelect,
  defaultValue,
}) {
  const [selectedImage, setSelectedImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setImagePreview("");
    }
  }, [selectedImage]);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      if (onImageSelect) {
        onImageSelect(file);
      }
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "#FFF",
        borderRadius: "10px",
        border: "2px solid #CFD0D2",
        p: "0.5em 1em",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: "1em",
      }}
    >
      <Box
        sx={{
          bgcolor: "#F4F5F7",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "5px",
          "& > *": { color: "#B1B1B1" },
        }}
      >
        {icon}
      </Box>

      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          {label}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1em",
          }}
        >
          {selectedImage && (
            <img src={imagePreview} alt="" style={{ width: "100%" }} />
          )}

          {defaultValue && !selectedImage && (
            <img src={defaultValue} alt="" style={{ width: "100%" }} />
          )}

          <input
            type="file"
            id="image-upload"
            onChange={handleImageSelect}
            style={{ display: "none" }}
          />
          <label htmlFor="image-upload" style={{ width: "100%" }}>
            <Button
              fullWidth
              variant="contained"
              component="span"
              sx={{
                bgcolor: "#E9E9E9",
                color: "#202020",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.5em",
                "&:hover": {
                  bgcolor: "#D9D9D9",
                },
              }}
            >
              <Box
                sx={{
                  bgcolor: "primary.main",
                  p: "3px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                }}
              >
                <Upload sx={{ width: "20px", height: "20px", color: "#FFF" }} />
              </Box>
              Upload Cover
            </Button>
          </label>
        </Box>
      </Box>
    </Box>
  );
}
