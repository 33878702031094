import {
  AppBar,
  Avatar,
  Box,
  Button,
  Toolbar,
  Typography,
} from "@mui/material";
import TabsComponent from "../Components/TabsComponent";
import { ExpandMore } from "@mui/icons-material";
import DropDownMenu from "../Components/DropDownMenu";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import {
  SystemUpdateAlt,
  ChangeCircle,
  QrCodeScanner,
  Logout,
} from "@mui/icons-material";
import Dashboard from "../Pages/Dashboard";

export default function NavBar() {
  const tabs = ["Tickets", "Report"];

  const profileDropDown = [
    {
      icon: <HomeIcon sx={{ transition: "color 0.5s ease-in-out" }} />,
      text: "Home",
    },
    {
      icon: <SystemUpdateAlt sx={{ transition: "color 0.5s ease-in-out" }} />,
      text: "Update Profile",
    },
    {
      icon: <ChangeCircle sx={{ transition: "color 0.5s ease-in-out" }} />,
      text: "Change Information",
    },
    {
      icon: <QrCodeScanner sx={{ transition: "color 0.5s ease-in-out" }} />,
      text: "Scan QR Code",
    },
    {
      icon: (
        <Logout sx={{ transition: "color 0.5s ease-in-out", color: "red" }} />
      ),
      text: "Logout",
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  const navigate = useNavigate();
  const path = window.location.pathname;

  const data = useSelector((state) => state.updateProfile.data);

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleItemSelect = (selectedItem) => {
    setSelectedItem(selectedItem.index);
    handleClose();
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("eventAdmin");
    navigate("/");
  };

  useEffect(() => {
    if (selectedItem === 0) {
      navigate("/");
    } else if (selectedItem === 1) {
      navigate("/update-profile");
    } else if (selectedItem === 2) {
      navigate("/change-information");
    } else if (selectedItem === 3) {
      navigate("/scanner");
    } else if (selectedItem === 4) {
      handleLogout();
    }
  }, [selectedItem]);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          bgcolor: "#FFF",
          boxShadow: "0px 4px 18px #E5E5E5",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "2em",
            }}
          >
            <Box
              component={Link}
              to={"/dashboard"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="./images/pyxizBlueLogo.png"
                alt=""
                style={{ maxHeight: "50px" }}
              />
            </Box>
            {(path === "/" || path === "/dashboard") && (
              <TabsComponent data={tabs} onTabChange={handleTabChange} />
            )}
          </Box>
          <Button
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1em",
            }}
            onClick={handleClick}
          >
            <Avatar src={data?.image} alt="user avatar" />
            <Typography variant="subtitle1" sx={{ color: "#202020" }}>
              {data?.username}
            </Typography>
            <ExpandMore
              sx={{
                transform: `rotate(${openMenu ? "180deg" : 0})`,
                transition: "0.3s ease-in-out",
                color: openMenu ? "#25AAE1" : "#000",
              }}
            />
          </Button>
          <DropDownMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            data={profileDropDown}
            handleItemSelect={handleItemSelect}
          />
        </Toolbar>
      </AppBar>
      {(path === "/" || path === "/dashboard") && (
        <Dashboard selectedTab={selectedTab} />
      )}
    </>
  );
}
