import { configureStore } from "@reduxjs/toolkit";
// import thunkMiddleware from "redux-thunk";
import loginReducer from "./Slices/Login";
import registerReducer from "./Slices/Register";
import verifyReducer from "./Slices/Verification";
import dashboardTicketsReducer from "./Slices/DashboardTickets";
import updateProfileReducer from "./Slices/UpdateProfileSlice";
import dashboardReportSlice from "./Slices/DashboardReport";
import ticketsReducer from "./Slices/Tickets";

const store = configureStore({
  reducer: {
    login: loginReducer,
    register: registerReducer,
    verify: verifyReducer,
    dashboardTickets: dashboardTicketsReducer,
    updateProfile: updateProfileReducer,
    dashboardReport: dashboardReportSlice,
    tickets: ticketsReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(thunkMiddleware),
  // devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
