import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "./../Loader";
import ErrorMessage from "./../ErrorMessage";
import DropDownMenu from "../DropDownMenu";
import { Box, Button, IconButton } from "@mui/material";
import { useState } from "react";
import {
  MoreVert,
  Edit,
  Delete,
  Cancel,
  CheckCircle,
} from "@mui/icons-material";
import PopupDialog from "../Dialog/CreateDialog";
import { useDispatch } from "react-redux";
import { sendCertificateAction } from "../../Store/Actions/DashboardReport";
import { registrationEventId } from "../../Constants";

const dropDownData = [
  {
    text: "Edit",
    icon: (
      <Edit color="primary" sx={{ transition: "color 0.3s ease-in-out" }} />
    ),
  },

  {
    text: "Delete",
    icon: (
      <Delete color="primary" sx={{ transition: "color 0.3s ease-in-out" }} />
    ),
  },
];

export default function DataTable({
  data,
  loading,
  error,
  totalRows,
  handleRows,
  handlePage,
  rowsPerPage,
  page,
  hasControls = false,
  selectedItem,
  selectedRow,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rotateIndex, setRotateIndex] = useState(null);
  const [certLoading, setCertLoading] = useState(false);
  const [sentCertificates, setSentCertificates] = useState({});

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    handlePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    handleRows(+event.target.value);
  };

  const handleCertificateClick = async (guestId) => {
    setCertLoading(true);

    const formData = new FormData();

    formData.append("guestId", guestId);
    formData.append("registrationEventId", registrationEventId);

    const response = await dispatch(sendCertificateAction(formData));

    if (response === 200) {
      setSentCertificates((prev) => ({
        ...prev,
        [guestId]: true,
      }));

      setCertLoading(false);
    } else {
      alert("Something went wrong");
      setCertLoading(false);
    }
  };

  const headers = Object.keys((data && data[0]) || {});

  const handleClick = (event, index, row) => {
    setAnchorEl(event.currentTarget);
    setRotateIndex(index);
    selectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRotateIndex(null);
  };

  const handleItemSelect = (item) => {
    selectedItem(item);
    handleClose();
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          {loading ? (
            <Loader />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : (
            <>
              <TableHead>
                <TableRow>
                  {headers?.map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        bgcolor: "#FFF",
                        color: "#969696",
                        borderBottom: 0,
                      }}
                    >
                      {header.toUpperCase()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.map((item) => item)
                  .map((row, rowIndex) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                    >
                      {headers?.map((key, cellIndex) => (
                        <TableCell key={cellIndex} sx={{ borderBottom: 0 }}>
                          {key === "age" || key === "crowdedTime" ? (
                            row[key]?.includes("null") ||
                            row[key]?.includes("Invalid") ? (
                              "N/A"
                            ) : (
                              row[key]
                            )
                          ) : row[key] !== null || row[key] !== undefined ? (
                            key === "Certificate" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "0.5em",
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="error"
                                  disabled={
                                    sentCertificates[row?.id] || certLoading
                                  }
                                  onClick={() =>
                                    handleCertificateClick(row?.id)
                                  }
                                  fullWidth
                                >
                                  {sentCertificates[row?.id] || certLoading
                                    ? "Sent"
                                    : "Send"}
                                </Button>
                                {sentCertificates[row?.id] ? (
                                  <CheckCircle
                                    sx={{
                                      color: "#12cc3e",
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  />
                                ) : (
                                  <Cancel
                                    sx={{
                                      color: "#6C757D",
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  />
                                )}
                              </Box>
                            ) : (
                              row[key]
                            )
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                      ))}
                      {hasControls && (
                        <TableCell sx={{ borderBottom: 0 }}>
                          <IconButton
                            onClick={(event) =>
                              handleClick(event, rowIndex, row)
                            }
                            sx={{
                              "&:hover": { "& > *": { color: "primary.main" } },
                            }}
                          >
                            <MoreVert
                              sx={{
                                color:
                                  rotateIndex === rowIndex
                                    ? "primary.main"
                                    : "#6C757D",
                                transform: `rotate(${
                                  rotateIndex === rowIndex ? "90deg" : "0"
                                })`,
                                transition: "0.3s ease-in-out",
                              }}
                            />
                          </IconButton>

                          <DropDownMenu
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            data={dropDownData}
                            handleItemSelect={handleItemSelect}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage ?? data?.length}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
