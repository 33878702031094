import { Box, Typography } from "@mui/material";
import DashboardNoData from "./NoData/Dashboard";

export default function DashboardItem({ title, image, caption }) {
  return (
    <Box
      sx={{
        boxShadow: "0px 4px 18px rgba(216, 216, 216, 0.6)",
        width: "100%",
      }}
    >
      <Box sx={{ bgcolor: "#FAFAFA", p: "0.5em 1em", borderRadius: "5px" }}>
        <Typography variant="subtitle1" sx={{ color: "#081531" }}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ p: caption ? "0" : "1em" }}>
        {caption ? (
          <Box
            sx={{
              bgcolor: "#FFF",
              p: "1em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <img src={image} style={{ height: "100px" }} alt="" />
            <Typography variant="body1">{caption}</Typography>
          </Box>
        ) : (
          <DashboardNoData />
        )}
      </Box>
    </Box>
  );
}
