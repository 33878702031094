import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import DataTable from "../../../Components/DataTable/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { dashboardTicketsAction } from "../../../Store/Actions/DashboardTickets";
import moment from "moment";
import Loader from "../../../Components/Loader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { MoreVert, Edit, Search, Delete } from "@mui/icons-material";
import DropDownMenu from "../../../Components/DropDownMenu";
import { getContentPageAction } from "../../../Store/Actions/Tickets";
import CreatePopupDialog from "../../../Components/Dialog/CreateDialog";
import UpdatePopupDialog from "../../../Components/Dialog/UpdateDialog";
import DeletePopupDialog from "../../../Components/Dialog/DeleteDialog";
import ChooseDialog from "../../../Components/Dialog/ChooseDialog";
import CreateContentDialog from "../../../Components/Dialog/ContentDialog";
import DashboardNoData from "./../../../Components/NoData/Dashboard";
import DeletePopupContentDialog from "../../../Components/Dialog/DeleteContentDialog";
import UpdateImage from "../../../Components/Dialog/UpdateImage";
import UpdateContentDialog from "../../../Components/Dialog/UpdateContentDialog";

const dropDownData = [
  {
    text: "Edit",
    icon: (
      <Edit color="primary" sx={{ transition: "color 0.3s ease-in-out" }} />
    ),
  },

  {
    text: "Delete",
    icon: (
      <Delete color="primary" sx={{ transition: "color 0.3s ease-in-out" }} />
    ),
  },
];

export default function Tickets() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rotateIndex, setRotateIndex] = useState(null);
  const [openChooseDialog, setOpenChooseDialog] = useState(false);
  const [openCreateTicket, setOpenCreateTicket] = useState(false);
  const [openCreateContent, setOpenCreateContent] = useState(false);
  const [openCreateImage, setOpenCreateImage] = useState(false);
  const [openUpdateContentDialog, setOpenUpdateContentDialog] = useState(false);
  const [openDeleteContentDialog, setOpenDeleteContentDialog] = useState(false);

  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedContent, setSelectedContent] = useState();
  const [searchText, setSearchText] = useState();

  const searchTermChanged = (e) => setSearchText(e?.target?.value);

  const dispatch = useDispatch();

  const ticketsData = useSelector((state) => state.dashboardTickets.data);
  const loading = useSelector((state) => state.dashboardTickets.loading);
  const error = useSelector((state) => state.dashboardTickets.error);

  // useEffect(() => {
  //   dispatch(dashboardTicketsAction(page, rowsPerPage));
  // }, [dispatch, page, rowsPerPage]);

  const fetchTicket = useCallback(
    ({ searchText, page, rowsPerPage }) => {
      dashboardTicketsAction({
        page,
        rowsPerPage,
        text: searchText?.length ? searchText : undefined,
      })(dispatch);
    },
    [dispatch]
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (typeof searchText === "undefined") return;

      fetchTicket({ searchText, page, rowsPerPage });
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText, fetchTicket, page, rowsPerPage]);

  useEffect(() => {
    fetchTicket({ page, rowsPerPage });
  }, [fetchTicket, page, rowsPerPage]);

  const contentData = useSelector(
    (state) => state.tickets.initialContentState.data
  );
  const contentLoading = useSelector(
    (state) => state.tickets.initialContentState.loading
  );
  const contentError = useSelector(
    (state) => state.tickets.initialContentState.error
  );

  useEffect(() => {
    dispatch(getContentPageAction());
  }, [dispatch]);

  const filteredTicketsData = ticketsData?.map((item) => {
    const {
      itemsList,
      endDate,
      startDate,
      userId,
      registrationEventId,
      description,
      capacity,
      price,
      oldPrice,
      currency,
      minPeerBooking,
      maxPeerBooking,
      id,
      title,
      reservationType,
      location,
      detailedLocation,
      category,
      image,
      status,
      repeatedReservation,
      updatedAt,
      createdAt,
      deletedAt,
      ...rest
    } = item;

    const formattedStartDate = moment(startDate).format("DD/MM/YYYY hh:mm A");
    const formattedEndDate = moment(endDate).format("DD/MM/YYYY hh:mm A");
    const ticketPrice = price + " " + currency;
    const discountPrice = oldPrice + " " + currency;

    return {
      id,
      Name: title,
      ...rest,
      "Ticket Price": ticketPrice,
      "Discounted Price": discountPrice,
      "Start Date": formattedStartDate,
      "End Date": formattedEndDate,
    };
  });

  const handleRows = (v) => {
    setRowsPerPage(v);
  };
  const handlePage = (v) => {
    setPage(v);
  };

  const handleClick = (event, item, index) => {
    setAnchorEl(event.currentTarget);
    setRotateIndex(index);
    setSelectedContent(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRotateIndex(null);
  };

  const handleContentSelect = async (selectedItem) => {
    if (selectedItem.text === "Edit") {
      // const response = await dispatch(updateTicketAction(formData));

      setOpenUpdateContentDialog(true);
    } else if (selectedItem.text === "Delete") {
      setOpenDeleteContentDialog(true);
    }
  };

  const handleTicketSelect = async (selectedItem) => {
    if (selectedItem.text === "Edit") {
      // const response = await dispatch(updateTicketAction(formData));

      setOpenUpdateDialog(true);
    } else if (selectedItem.text === "Delete") {
      setOpenDeleteDialog(true);
    }
  };

  const handleOpenChooseDialog = () => {
    setOpenChooseDialog(true);
  };

  const handleOpenCreateImage = () => {
    setOpenCreateImage(true);
  };
  const handleOpenCreateTicket = () => {
    setOpenCreateTicket(true);
  };

  const handleOpenCreateContent = () => {
    setOpenCreateContent(true);
  };

  const handleOpenUpdateDialog = () => {
    setOpenUpdateDialog(true);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenChooseDialog(false);
    setOpenCreateContent(false);
    setOpenCreateTicket(false);
    setOpenUpdateContentDialog(false);
    setOpenDeleteContentDialog(false);
    setOpenUpdateDialog(false);
    setOpenDeleteDialog(false);
    setOpenCreateImage(false);
    handleClose();
  };

  const handleSelectedRow = (row) => {
    const selectedData = ticketsData?.find((item) => item.id === row.id);

    if (selectedData) {
      setSelectedRow(selectedData);
    } else {
      console.log("Item not found in data array");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          gap: "1em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ color: "#202020" }}>
            Tickets
          </Typography>

          <Box sx={{ display: "flex", gap: "2em" }}>
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              onChange={searchTermChanged}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ bgcolor: "#F9F9F9" }}
            />

            <Button
              variant="contained"
              onClick={handleOpenChooseDialog}
              sx={{ p: "0.5em 3em", borderRadius: "8px" }}
            >
              Tickets System
            </Button>
          </Box>
        </Box>

        <Box>
          <Box sx={{ p: "1em 0" }}>
            <Typography variant="subtitle1" sx={{ color: "#081531" }}>
              Content Page
            </Typography>
          </Box>

          <Box
            sx={{
              p: "1em 0",
              borderRadius: "5px",
              bgcolor: "#FFF",
              boxShadow: "0px 4px 18px #D8D8D8",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {contentError ? (
              <ErrorMessage message={contentError} />
            ) : contentLoading ? (
              <Loader />
            ) : (
              contentData?.length > 0 &&
              contentData?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    bgcolor: "#F9F9F9",
                    p: "0.5em 1em",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" sx={{ color: "#616161" }}>
                      {item?.name}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#202020" }}>
                      {item?.details}
                    </Typography>
                  </Box>

                  <IconButton
                    onClick={(event) => handleClick(event, item, index)}
                    sx={{ "&:hover": { "& > *": { color: "primary.main" } } }}
                  >
                    <MoreVert
                      sx={{
                        color:
                          rotateIndex === index ? "primary.main" : "#6C757D",
                        transform: `rotate(${
                          rotateIndex === index ? "90deg" : "0"
                        })`,
                        transition: "0.3s ease-in-out",
                      }}
                    />
                  </IconButton>

                  <DropDownMenu
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    data={dropDownData}
                    handleItemSelect={handleContentSelect}
                  />
                </Box>
              ))
            )}
          </Box>
        </Box>

        <Box>
          <Box sx={{ p: "1em 0" }}>
            <Typography variant="subtitle1" sx={{ color: "#081531" }}>
              Tickets List
            </Typography>
          </Box>
          <Box sx={{ boxShadow: "0px 4px 18px #D8D8D8" }}>
            {ticketsData ? (
              <DataTable
                data={filteredTicketsData}
                loading={loading}
                error={error}
                totalPages={ticketsData?.meta?.allPages}
                totalRows={ticketsData?.meta?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                handleRows={handleRows}
                handlePage={handlePage}
                hasControls
                selectedItem={handleTicketSelect}
                openUpdateDialog={handleOpenUpdateDialog}
                openDeleteDialog={handleOpenDeleteDialog}
                selectedRow={handleSelectedRow}
              />
            ) : (
              <DashboardNoData />
            )}
          </Box>
        </Box>
      </Box>

      {openChooseDialog && (
        <ChooseDialog
          open={openChooseDialog}
          close={handleCloseDialog}
          openContent={handleOpenCreateContent}
          openTicket={handleOpenCreateTicket}
          openImage={handleOpenCreateImage}
        />
      )}

      {openCreateContent && (
        <CreateContentDialog
          open={openCreateContent}
          close={handleCloseDialog}
        />
      )}

      {openCreateImage && (
        <UpdateImage open={openCreateImage} close={handleCloseDialog}  />
      )}

      {openCreateTicket && (
        <CreatePopupDialog open={openCreateTicket} close={handleCloseDialog} />
      )}

      {openUpdateContentDialog && (
        <UpdateContentDialog
          open={openUpdateContentDialog}
          close={handleCloseDialog}
          data={selectedContent}
        />
      )}
      {openDeleteContentDialog && (
        <DeletePopupContentDialog
          open={openDeleteContentDialog}
          close={handleCloseDialog}
          data={selectedContent}
        />
      )}

      {openUpdateDialog && (
        <UpdatePopupDialog
          open={openUpdateDialog}
          close={handleCloseDialog}
          data={selectedRow}
        />
      )}

      {openDeleteDialog && (
        <DeletePopupDialog
          open={openDeleteDialog}
          close={handleCloseDialog}
          data={selectedRow}
        />
      )}
    </>
  );
}
