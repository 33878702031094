import { Box, Typography } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

export default function PieChartComponent({ title, data }) {
  return (
    <Box
      sx={{
        boxShadow: "0px 4px 18px rgba(216, 216, 216, 0.6)",
        width: "100%",
      }}
    >
      <Box sx={{ bgcolor: "#FAFAFA", p: "0.5em 1em", borderRadius: "5px" }}>
        <Typography variant="subtitle1" sx={{ color: "#081531" }}>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: "#FFF",
          p: "1em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: data ? "start" : "center",
          minWidth: "200px",
          minHeight: "150px",
        }}
      >
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: data?.male, label: `${data?.male} Male` },
                {
                  id: 1,
                  value: data?.female,
                  label: `${data?.female} Female`,
                },
                {
                  id: 2,
                  value: data?.unspecified,
                  label: `${data?.unspecified} Unspecified`,
                },
              ],
              // arcLabel: (item) => `${item.value}%`,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: {
                innerRadius: 20,
                additionalRadius: -10,
                color: "gray",
              },
              innerRadius: 30,
              outerRadius: 60,
              paddingAngle: 5,
              cornerRadius: 5,
            },
          ]}
          // sx={{
          //   [`& .${pieArcLabelClasses.root}`]: {
          //     fill: "#FFF",
          //     fontWeight: "400",
          //     fontSize: "8px",
          //   },
          // }}
        />
      </Box>
    </Box>
  );
}
