import { Box, TextField, Typography } from "@mui/material";

export default function TicketDefaultField({
  name,
  icon,
  placeholder,
  formik,
  values,
  onChange,
  type,
  label,
  defaultValue,
  ...props
}) {
  return (
    <Box
      sx={{
        bgcolor: "#FFF",
        borderRadius: "10px",
        border: "2px solid #CFD0D2",
        p: "0.5em 1em",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: "1em",
      }}
    >
      <Box
        sx={{
          bgcolor: "#F4F5F7",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "5px",
          "& > *": { color: "#B1B1B1" },
        }}
      >
        {icon}
      </Box>

      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          {label}
        </Typography>
        <TextField
          {...props}
          type={type ?? "text"}
          id={name}
          name={name}
          fullWidth
          variant="standard"
          value={formik?.values[name] || values || ""}
          onChange={onChange || formik?.handleChange}
          error={(formik?.touched[name] && Boolean(formik?.errors[name])) || ""}
          helperText={formik.touched[name] && formik.errors[name]}
          placeholder={placeholder}
          defaultValue={defaultValue ?? ""}
          InputProps={{
            disableUnderline: true,
            style: { fontWeight: 500 },
          }}
        />
      </Box>
    </Box>
  );
}
