import axios from "axios";
import { registrationEventId } from "../Constants";

export const createPackageTicketService = async (formData) => {
  const authToken = localStorage.getItem("authToken");
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/page/reservation`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    }
  );
};

export const createSingleTicketService = async (formData) => {
  const authToken = localStorage.getItem("authToken");
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/page/reservation`,
    formData,
    {
      headers: {
        Authorization: authToken,
      },
    }
  );
};

export const updatePackageTicketService = async (formData) => {
  const authToken = localStorage.getItem("authToken");
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/page/reservation`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    }
  );
};

export const updateSingleTicketService = async (formData) => {
  const authToken = localStorage.getItem("authToken");
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/page/reservation`,
    formData,
    {
      headers: {
        Authorization: authToken,
      },
    }
  );
};

export const deleteTicketService = async ({ reservationId }) => {
  const authToken = localStorage.getItem("authToken");
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/page/reservation`,
    {
      params: {
        reservationId: reservationId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    }
  );
};

export const createContentPageService = async (formData) => {
  const authToken = localStorage.getItem("authToken");
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/registration-event`,
    formData,
    {
      headers: {
        Authorization: authToken,
      },
    }
  );
};

export const addContentPageService = async (content) => {
  const authToken = localStorage.getItem("authToken");
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/registration-event/content`,
    content,
    {
      headers: {
        Authorization: authToken,
      },
    }
  );
};

export const getContentPageService = async () => {
  const authToken = localStorage.getItem("authToken");
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/registration-event`,
    {
      params: {
        id: registrationEventId,
      },
      headers: {
        Authorization: authToken,
      },
    }
  );
};

export const updateContentPageService = async (content) => {
  const authToken = localStorage.getItem("authToken");
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/registration-event/content`,
    content,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    }
  );
};

export const deleteContentPageService = async ({ id }) => {
  const authToken = localStorage.getItem("authToken");
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/registration-event/content`,
    {
      params: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    }
  );
};
