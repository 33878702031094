import { Box, Typography } from "@mui/material";

export default function DashboardNoData() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        alignItems: "center",
      }}
    >
      <img src="./images/dashboardNoData.svg" alt="" />
      <Typography variant="h6" sx={{ color: "#6C757D", fontWeight: "400" }}>
        You have no data yet
      </Typography>
    </Box>
  );
}
