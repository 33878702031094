import { createSlice } from "@reduxjs/toolkit";

const initialContentState = {
  data: [],
  loading: false,
  error: null,
  image: "",
};

const initialState = {
  data: [],
  loading: false,
  error: null,
  initialContentState,
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    ticketLoading(state) {
      state.loading = true;
      state.error = null;
    },
    ticketError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setTicketList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },

    contentLoading(state) {
      state.initialContentState.loading = true;
      state.error = null;
    },
    contentError(state, action) {
      state.initialContentState.loading = false;
      state.error = action.payload;
    },
    setContentList(state, action) {
      state.initialContentState.loading = false;
      state.initialContentState.data = action.payload?.content;
      state.initialContentState.image = action.payload?.image;
    },
    addContent(state, action) {
      state.initialContentState.loading = false;
      state.initialContentState.error = false;

      state.initialContentState.data.push(action.payload);
    },
    createContent(state, action) {
      state.initialContentState.loading = false;
      state.initialContentState.error = false;

      state.initialContentState.image = action.payload;
    },
    editContent(state, action) {
      const foundProduct = state.initialContentState.data.filter(
        (e) => e.id === action?.payload?.id
      )[0];
      const index = state.initialContentState.data.indexOf(foundProduct);
      state.initialContentState.data[index] = {
        ...state.initialContentState.data[index],
        ...action?.payload,
      };
      state.initialContentState.loading = false;
    },
    deleteContent(state, action) {
      state.initialContentState.loading = false;


      state.initialContentState.data = state.initialContentState.data.filter(
        (e) => e.id !== action.payload?.id
      );
    },
  },
});

export const {
  ticketLoading,
  ticketError,
  setTicketList,
  contentLoading,
  contentError,
  setContentList,
  addContent,
  editContent,
  deleteContent,
  createContent,
} = ticketSlice.actions;

export default ticketSlice.reducer;
