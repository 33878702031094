import axios from "axios";
import { registrationEventId } from "../Constants";

export const dashboardReportService = async () => {
  return await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/registration-event/ticket-stats?registrationEventId=${registrationEventId}`
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Unable to fetch Building data: ${error.message}`);
    });
};

export const usersStatsService = async () => {
  return await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/registration-event/users-stats?registrationEventId=${registrationEventId}`
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Unable to fetch Building data: ${error.message}`);
    });
};

export const usersListService = async () => {
  return await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/registration-event/users?registrationEventId=${registrationEventId}`
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Unable to fetch Building data: ${error.message}`);
    });
};

export const sendCertificateService = async (formData) => {
  const authToken = localStorage.getItem("authToken");
  return await axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/api/registration-event/certificate`,
      formData,
      {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      }
    )

    .then((response) => response.status)
    .catch((error) => {
      throw new Error(`Unable to fetch Users stats data: ${error.message}`);
    });
};