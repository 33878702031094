import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function TicketMultiField({
  name,
  icon,
  placeholder,
  formik,
  onChange,
  type,
  label,
  itemValue,
  defaultValue,
  ...props
}) {
  const [inputValue, setInputValue] = useState("");
  const [values, setValues] = useState([]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue.trim() !== "") {
        const newItem = { name: inputValue.trim(), value: itemValue };
        setValues([...values, newItem]);

        // Update Formik values
        const newValues = [...(formik.values[name] || []), newItem];
        formik.setFieldValue(name, newValues);

        setInputValue("");
      }
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "#FFF",
        borderRadius: "10px",
        border: "2px solid #CFD0D2",
        p: "0.5em 1em",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: "1em",
      }}
    >
      <Box
        sx={{
          bgcolor: "#F4F5F7",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "5px",
          "& > *": { color: "#B1B1B1" },
        }}
      >
        {icon}
      </Box>

      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          {label}
        </Typography>
        <TextField
          {...props}
          placeholder="Add"
          variant="standard"
          id={name}
          name={name}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          error={(formik?.touched[name] && Boolean(formik?.errors[name])) || ""}
          helperText={formik.touched[name] && formik.errors[name]}
          multiline
          fullWidth
        />
        <ul>
          {values.map((item, index) => (
            <li key={index}>{item.name}</li>
          ))}
        </ul>
      </Box>
    </Box>
  );
}
