import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import TicketCoverField from "../InputFields/TicketCover";
import { Image } from "@mui/icons-material";
import { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { registrationEventId } from "../../Constants";
import { createContentPageAction } from "../../Store/Actions/Tickets";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateImage = ({ open, close }) => {
  const [loading, setLoading] = useState();
  const [formError, setFormError] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const image = useSelector((state) => state.tickets.initialContentState.image);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {},
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (loading) return;
        setLoading(true);
        setFormError("");

        const formData = new FormData();

        formData.append("id", registrationEventId);
        selectedImage && formData.append("image", selectedImage);

        const response = await dispatch(createContentPageAction(formData));

        if (response?.status !== 200) {
          setFormError(response);
          return setLoading(false);
        }
        setLoading(false);

        return close();
      } catch (error) {
        setFormError(error);
        setLoading(false);
      }
      setLoading(false);
    },
  });

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px",
          bgcolor: "#F4F5F7",
          maxHeight: "100%",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <img src="./images/dialogBg.png" alt="" style={{ width: "100%" }} />
        <img
          src="./images/dialogVectors.png"
          alt=""
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#FFF",
          }}
        >
          <span style={{ color: "#25AAE1" }}>Edit</span> Content image
        </Typography>
      </Box>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
      >
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TicketCoverField
                name={"image"}
                icon={<Image />}
                label={"Page Cover"}
                onImageSelect={handleImageSelect}
                defaultValue={image}
              />
            </Grid>

            {formError && (
              <Grid item xs={12}>
                <FormHelperText
                  sx={{
                    color: "red",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {formError}
                </FormHelperText>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "stretch",
                  gap: "1em",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={close}
                  sx={{ p: "0.5em 3em" }}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  sx={{ p: "0.5em 3em" }}
                >
                  done
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateImage;
