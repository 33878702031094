import { Box, Grid } from "@mui/material";
import Tickets from "./Tickets";
import Report from "./Report";

export default function Dashboard({ selectedTab }) {
  return (
    <Box sx={{ p: "2em", minHeight: "100vh" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: "1em",
            }}
          >
            {{ 0: <Tickets />, 1: <Report /> }[selectedTab]}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
