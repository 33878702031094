import * as React from "react";
import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChooseDialog({
  open,
  close,
  openContent,
  openTicket,
  openImage,
}) {
  const handleOpenImage = () => {
    openImage();
  };
  const handleOpenContent = () => {
    openContent();
  };

  const handleOpenTicket = () => {
    openTicket();
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px",
          bgcolor: "#F4F5F7",
          maxHeight: "100%",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <img src="./images/dialogBg.png" alt="" style={{ width: "100%" }} />
        <img
          src="./images/dialogVectors.png"
          alt=""
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#FFF",
          }}
        >
          <span style={{ color: "#25AAE1" }}>Tickets</span> System
        </Typography>
      </Box>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          p: "1em",
          "& > Button": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            p: "1em",
            width: "100%",
            borderRadius: "5px",
            margin: "-2em 0 0 0",
            bgcolor: "#FFF",
            border: "1px solid #CFD0D2",
            "&:hover": {
              border: "1px solid #EA5069",
              bgcolor: "#CFD0D2",
            },
          },
        }}
      >
        <Button variant="contained" onClick={handleOpenImage}>
          <img src="./images/addContent.svg" alt="" />
          <Typography variant="subtitle1" sx={{ color: "#202020" }}>
            Content image
          </Typography>
        </Button>

        <Button variant="contained" onClick={handleOpenContent}>
          <img src="./images/addContent.svg" alt="" />
          <Typography variant="subtitle1" sx={{ color: "#202020" }}>
            Add Content
          </Typography>
        </Button>

        <Button variant="contained" onClick={handleOpenTicket}>
          <img src="./images/addTickets.svg" alt="" />
          <Typography variant="subtitle1" sx={{ color: "#202020" }}>
            Add Ticket
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
