import { Box, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function TicketDateField({
  startDate,
  endDate,
  icon,
  formik,
  values,
  label,
}) {
  const handleStartDateChange = (value) => {
    const formattedStartDate = dayjs(value).toISOString();
    formik.setFieldValue(startDate, formattedStartDate);
  };

  const handleEndDateChange = (value) => {
    const formattedEndDate = dayjs(value).toISOString();
    formik.setFieldValue(endDate, formattedEndDate);
  };

  const defaultStartDate = formik.values[startDate]
    ? new Date(values?.map((item) => item.startDate))
    : null;
  const defaultEndDate = formik.values[endDate]
    ? new Date(values?.map((item) => item.endDate))
    : null;

  return (
    <Box
      sx={{
        bgcolor: "#FFF",
        borderRadius: "10px",
        border: "2px solid #CFD0D2",
        p: "0.5em 1em",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: "1em",
      }}
    >
      <Box
        sx={{
          bgcolor: "#F4F5F7",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "5px",
          "& > *": { color: "#B1B1B1" },
        }}
      >
        {icon}
      </Box>

      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          {label}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            gap: "0.5em",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography variant="subtitle2">Start Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disablePast
                sx={{ width: "100%" }}
                id={startDate}
                name={startDate}
                value={defaultStartDate || ""}
                onChange={handleStartDateChange}
                error={
                  (formik?.touched[startDate] &&
                    Boolean(formik?.errors[startDate])) ||
                  ""
                }
                helperText={
                  formik.touched[startDate] && formik.errors[startDate]
                }
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Typography variant="subtitle2">End Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disablePast
                sx={{ width: "100%" }}
                id={endDate}
                name={endDate}
                value={defaultEndDate || ""}
                onChange={handleEndDateChange}
                error={
                  (formik?.touched[endDate] &&
                    Boolean(formik?.errors[endDate])) ||
                  ""
                }
                helperText={formik.touched[endDate] && formik.errors[endDate]}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "0.5em",
            mt: "1em",
          }}
        ></Box>
      </Box>
    </Box>
  );
}
