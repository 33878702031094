import { Box, Grid, Typography } from "@mui/material";
import DashboardItem from "../../../Components/DashboardItem";
import DataTable from "../../../Components/DataTable/DataTable";
import TitleBanner from "../../../Components/TitleBanner";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardReportAction,
  usersListAction,
  usersStatsAction,
} from "../../../Store/Actions/DashboardReport";
import { useEffect, useState } from "react";
import moment from "moment";
import PieChartComponent from "./../../../Components/PieChart";
import ErrorMessage from "../../../Components/ErrorMessage";
import Loader from "../../../Components/Loader";
import LineChartComponent from "../../../Components/LineChart";
import DashboardNoData from "../../../Components/NoData/Dashboard";
import TicketPieChart from "../../../Components/TicketPieChart";
import BarChartComponent from "./../../../Components/BarChart";

export default function Report() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const dispatch = useDispatch();

  const ticketData = useSelector((state) => state.dashboardReport.data);
  const ticketsLoading = useSelector((state) => state.dashboardReport.loading);
  const ticketsError = useSelector((state) => state.dashboardReport.error);

  const usersStatsData = useSelector(
    (state) => state.dashboardReport.initialStatsState.data
  );
  const usersStatsLoading = useSelector(
    (state) => state.dashboardReport.initialStatsState.loading
  );
  const usersStatsError = useSelector(
    (state) => state.dashboardReport.initialStatsState.error
  );

  const usersData = useSelector(
    (state) => state.dashboardReport.initialUsersState.data
  );
  const usersLoading = useSelector(
    (state) => state.dashboardReport.initialUsersState.loading
  );
  const usersError = useSelector(
    (state) => state.dashboardReport.initialUsersState.error
  );

  useEffect(() => {
    dispatch(dashboardReportAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(usersStatsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(usersListAction(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const filteredUsersData = usersData?.data?.map((item) => {
    const {
      image,
      createdAt,
      firstName,
      lastName,
      color,
      email,
      about,
      gender,
      age,
      mobile,
      guestLog,
      certificate,
      ...rest
    } = item;

    const formattedDate = moment(createdAt).format("DD/MM/YYYY hh:mm A");

    let status = guestLog?.length > 0 ? "Attended" : "Did not attend";

    let exitedEvent =
      guestLog?.length > 0
        ? guestLog.filter((item) => item?.exitUser).length
        : "-";

    return {
      ...rest,
      "Date of Registration": formattedDate,
      status,
      "# Exited": exitedEvent,
      Certificate: "",
    };
  });

  const handleRows = (v) => {
    setRowsPerPage(v);
  };
  const handlePage = (v) => {
    setPage(v);
  };

  let ticketImg = "./images/tickets.svg";
  let personImg = "./images/user.svg";


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        gap: "1em",
      }}
    >
      <TitleBanner text={"Today's Report"} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <DashboardItem
            title={"Tickets Sold"}
            image={ticketImg}
            caption={`${ticketData?.data?.totalTickets} Tickets`}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <DashboardItem
            title={"Top Ticket Bought"}
            image={ticketImg}
            caption={`${ticketData?.data?.topSales} Tickets`}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <DashboardItem
            title={"Ticket Sales"}
            image={ticketImg}
            caption={`${ticketData?.data?.sales} EGP`}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <DashboardItem
            title={"Customers"}
            image={personImg}
            caption={`${usersStatsData?.data?.attendees} People`}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ display: "flex", width: "100%" }}>
          {usersStatsLoading ? (
            <Loader />
          ) : usersStatsError ? (
            <ErrorMessage message={usersStatsError} />
          ) : usersStatsData?.data?.registers.male > 0 ||
            usersStatsData?.data?.registers.female > 0 ||
            usersStatsData?.data?.registers.unspecified > 0 ? (
            <PieChartComponent
              title={"Customer Types"}
              data={usersStatsData?.data?.registers}
            />
          ) : (
            <Box
              sx={{
                boxShadow: "0px 4px 18px rgba(216, 216, 216, 0.6)",
                width: "100%",
              }}
            >
              <Box
                sx={{ bgcolor: "#FAFAFA", p: "0.5em 1em", borderRadius: "5px" }}
              >
                <Typography variant="subtitle1" sx={{ color: "#081531" }}>
                  Customer Types
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#FFF",
                  p: "1em",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  minWidth: "200px",
                  minHeight: "150px",
                }}
              >
                <DashboardNoData />
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={6} sx={{ display: "flex", width: "100%" }}>
          {ticketsLoading ? (
            <Loader />
          ) : ticketsError ? (
            <ErrorMessage message={ticketsError} />
          ) : ticketData?.data?.salesByDate?.length > 0 ? (
            <TicketPieChart
              title={"Ticket Name"}
              data={ticketData?.data?.tickets}
            />
          ) : (
            <Box
              sx={{
                boxShadow: "0px 4px 18px rgba(216, 216, 216, 0.6)",
                width: "100%",
              }}
            >
              <Box
                sx={{ bgcolor: "#FAFAFA", p: "0.5em 1em", borderRadius: "5px" }}
              >
                <Typography variant="subtitle1" sx={{ color: "#081531" }}>
                  Ticket Name
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#FFF",
                  p: "1em",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  minWidth: "200px",
                  minHeight: "150px",
                }}
              >
                <DashboardNoData />
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", width: "100%" }}>
          {ticketsLoading ? (
            <Loader />
          ) : ticketsError ? (
            <ErrorMessage message={ticketsError} />
          ) : ticketData?.data?.salesByDate?.length > 0 ? (
            <BarChartComponent
              title={"The Most Country"}
              data={ticketData?.data?.locations}
            />
          ) : (
            <Box
              sx={{
                boxShadow: "0px 4px 18px rgba(216, 216, 216, 0.6)",
                width: "100%",
              }}
            >
              <Box
                sx={{ bgcolor: "#FAFAFA", p: "0.5em 1em", borderRadius: "5px" }}
              >
                <Typography variant="subtitle1" sx={{ color: "#081531" }}>
                  The Most Country
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#FFF",
                  p: "1em",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  minWidth: "200px",
                  minHeight: "150px",
                }}
              >
                <DashboardNoData />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", width: "100%" }}>
          {ticketsLoading ? (
            <Loader />
          ) : ticketsError ? (
            <ErrorMessage message={ticketsError} />
          ) : ticketData?.data?.salesByDate?.length > 0 ? (
            <LineChartComponent
              title={"Tickets"}
              data={ticketData?.data?.salesByDate || []}
            />
          ) : (
            <Box
              sx={{
                boxShadow: "0px 4px 18px rgba(216, 216, 216, 0.6)",
                width: "100%",
              }}
            >
              <Box
                sx={{ bgcolor: "#FAFAFA", p: "0.5em 1em", borderRadius: "5px" }}
              >
                <Typography variant="subtitle1" sx={{ color: "#081531" }}>
                  Tickets
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#FFF",
                  p: "1em",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  minWidth: "200px",
                  minHeight: "150px",
                }}
              >
                <DashboardNoData />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <TitleBanner text={"Data Of Users"} />

      <DataTable
        data={filteredUsersData}
        loading={usersLoading}
        error={usersError}
        totalPages={usersData?.meta?.allPages}
        totalRows={usersData?.meta?.count}
        rowsPerPage={rowsPerPage}
        page={page}
        handleRows={handleRows}
        handlePage={handlePage}
      />
    </Box>
  );
}
