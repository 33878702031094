import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import {
  LocalOffer,
  Menu,
  Paid,
  WatchLater,
  Widgets,
  Place,
  Image,
} from "@mui/icons-material";
import TicketDefaultField from "../InputFields/TicketDefault";
import TicketDateField from "../InputFields/TicketDate";
import TicketMultiField from "../InputFields/TicketMulti";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  createPackageTicketAction,
  createSingleTicketAction,
} from "../../Store/Actions/Tickets";
import { registrationEventId, reservationType } from "../../Constants/index";
import TicketCoverField from "../InputFields/TicketCover";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreatePopupDialog({ open, close }) {
  const [loading, setLoading] = useState();
  const [formError, setFormError] = useState("");
  const [ticketType, setTicketType] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string()
      .max(35, "Description must be at most 35 characters")
      .required("Description is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
    capacity: Yup.number()
      .min(0, "Capacity must be at least 0")
      .max(10000, "Capacity cannot exceed 10000")
      .required("Capacity is required"),
    oldPrice: Yup.number()
      .positive("Old Price must be a positive number")
      .required("Old Price is required"),
    price: Yup.number()
      .positive("Price must be a positive number")
      .required("Price is required"),
    location: Yup.string().required("Location is required"),
    detailedLocation: Yup.string().required("Detailed Location is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      startDate: "",
      endDate: "",
      capacity: "",
      oldPrice: "",
      price: "",
      itemsList: [],
      location: "",
      detailedLocation: "",
    },
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (loading) return;
        setLoading(true);
        setFormError("");

        const formData = new FormData();
        formData.append("title", values.title);
        if (ticketType === 0 && values.description) {
          formData.append("description", values.description);
        }
        formData.append("startDate", values.startDate);
        formData.append("endDate", values.endDate);
        formData.append("capacity", values.capacity);
        formData.append("oldPrice", values.oldPrice);
        formData.append("price", values.price);
        if (ticketType === 0 && values.itemsList) {
          values.itemsList.forEach((item, index) => {
            formData.append(`itemsList[${index}].name`, item.name);
            formData.append(`itemsList[${index}].value`, item.value);
          });
        }
        selectedImage && formData.append("image", selectedImage);
        if (ticketType === 1 && values.location) {
          formData.append("location", values.location);
        }
        if (ticketType === 1 && values.detailedLocation) {
          formData.append("detailedLocation", values.detailedLocation);
        }

        formData.append("minPeerBooking", 1);
        formData.append("maxPeerBooking", 5);
        formData.append("reservationType", reservationType);
        formData.append("registrationEventId", registrationEventId);
        formData.append("ticketType", ticketType);

        // if (ticketType === 0) {
        //   const response = await dispatch(createPackageTicketAction(formData));

        //   if (response?.status !== 200) {
        //     setFormError(response);
        //     return setLoading(false);
        //   }
        //   setLoading(false);
        // } else if (ticketType === 1) {
        const response = await dispatch(createSingleTicketAction(formData));

        if (response?.status !== 200) {
          setFormError(response);
          return setLoading(false);
        }
        setLoading(false);
        // }

        return close();
      } catch (error) {
        setFormError(error);
        setLoading(false);
      }
      setLoading(false);
    },
  });

  const handleTicketTypeChange = (event) => {
    setTicketType(parseInt(event.target.value, 10));
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px",
          bgcolor: "#F4F5F7",
          maxHeight: "100%",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <img src="./images/dialogBg.png" alt="" style={{ width: "100%" }} />
        <img
          src="./images/dialogVectors.png"
          alt=""
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#FFF",
          }}
        >
          <span style={{ color: "#25AAE1" }}>Add</span> Ticket
        </Typography>
      </Box>
      <DialogTitle>
        <Typography variant="subtitle1" sx={{ color: "#202020" }}>
          Ticket Type
        </Typography>
        <RadioGroup row value={ticketType} onChange={handleTicketTypeChange}>
          <FormControlLabel value={0} control={<Radio />} label="Package" />
          <FormControlLabel value={1} control={<Radio />} label="Single" />
        </RadioGroup>
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
      >
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TicketDefaultField
                name={"title"}
                icon={<LocalOffer sx={{ transform: "rotate(90deg)" }} />}
                placeholder={"Enter Ticket Name"}
                label={"Ticket Name"}
                formik={formik}
              />
            </Grid>

            {ticketType === 0 && (
              <Grid item xs={12}>
                <TicketDefaultField
                  name={"description"}
                  icon={<Menu />}
                  placeholder={"Enter Ticket Details, Write 35 letters"}
                  label={"Ticket Details"}
                  formik={formik}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TicketDateField
                startDate={"startDate"}
                endDate={"endDate"}
                icon={<WatchLater />}
                label={"Start & End dates"}
                formik={formik}
              />
            </Grid>

            <Grid item xs={12}>
              <TicketDefaultField
                name={"capacity"}
                icon={<Widgets sx={{ transform: "rotate(270deg)" }} />}
                placeholder={"Enter Ticket Quantity ex, 10000 ticket"}
                label={"Ticket Quantity"}
                formik={formik}
              />
            </Grid>

            <Grid item xs={12}>
              <TicketDefaultField
                name={"oldPrice"}
                icon={<Paid />}
                placeholder={"Enter The Original Ticket Price Ex, 480 EGP"}
                label={"The Original Ticket Price"}
                formik={formik}
              />
            </Grid>

            <Grid item xs={12}>
              <TicketDefaultField
                name={"price"}
                icon={<Paid />}
                placeholder={"Enter Ticket Price Discount ex, 400 EGP"}
                label={"Ticket Price Discounted"}
                formik={formik}
              />
            </Grid>

            {ticketType === 0 && (
              <>
                <Grid item xs={12}>
                  <TicketMultiField
                    name={"itemsList"}
                    icon={<Menu />}
                    placeholder={"Enter Access"}
                    label={"You Will Get Access On"}
                    formik={formik}
                    itemValue={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TicketMultiField
                    name={"itemsList"}
                    icon={<Menu />}
                    placeholder={"Enter No Access"}
                    label={"You Won't Get Access On"}
                    formik={formik}
                    itemValue={false}
                  />
                </Grid>
              </>
            )}

            {ticketType === 1 && (
              <>
                <Grid item xs={12}>
                  <TicketDefaultField
                    name={"location"}
                    icon={<Place />}
                    placeholder={"Enter Country"}
                    label={"Country"}
                    formik={formik}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TicketDefaultField
                    name={"detailedLocation"}
                    icon={<Place />}
                    placeholder={"Enter The Specific Address"}
                    label={"The Specific Address"}
                    formik={formik}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TicketCoverField
                name={"image"}
                icon={<Image />}
                label={"Ticket Cover"}
                onImageSelect={handleImageSelect}
              />
            </Grid>

            {formError && (
              <Grid item xs={12}>
                <FormHelperText
                  sx={{
                    color: "red",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {formError}
                </FormHelperText>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "stretch",
                  gap: "1em",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={close}
                  sx={{ p: "0.5em 3em" }}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  sx={{ p: "0.5em 3em" }}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
