import { Box, Typography } from "@mui/material";
import React from "react";

const ErrorMessage = ({ message }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: "1em",
      }}
    >
      <Typography variant="subtitle1" color="error">
        Error: {message}
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
