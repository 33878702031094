import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: [],
  loading: false,
  error: null,
};

const dashboardTicketsSlice = createSlice({
  name: "dashboardTickets",
  initialState,
  reducers: {
    ticketsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    ticketsError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setTicketsList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    addTicket(state, action) {
      state.loading = false;
      state.error = false;

      state.data.push(action.payload);
    },
    editTicket(state, action) {
      const foundProduct = state.data.filter(
        (e) => e.id === action?.payload?.id
      )[0];
      const index = state.data.indexOf(foundProduct);

      state.data[index] = { ...state.data[index], ...action?.payload };
      state.loading = false;
    },
    deleteTicket(state, action) {
      state.loading = false;
      state.data = state.data.filter((e) => e.id !== action.payload?.id);
    },
  },
});

export const {
  ticketsLoading,
  ticketsError,
  setTicketsList,
  addTicket,
  editTicket,
  deleteTicket,
} = dashboardTicketsSlice.actions;

export default dashboardTicketsSlice.reducer;
