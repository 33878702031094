import { List, ListItem, ListItemText, Popover } from "@mui/material";

export default function DropDownMenu({
  anchorEl,
  handleClose,
  data,
  handleItemSelect,
}) {
  return (
    <Popover
      disableScrollLock={true}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <List>
        {data?.map((item, index) => {
          return (
            <ListItem
              button
              key={index}
              onClick={() => handleItemSelect({ index, text: item?.text })}
              sx={{
                transition: "background-color 0.5s ease-in-out",
                "&:hover": {
                  bgcolor: "#25AAE1",
                  "& > *": {
                    color: "#FFF",
                  },
                },
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "1em",
              }}
            >
              {item?.icon}
              <ListItemText
                primary={item?.text}
                sx={{
                  transition: "color 0.5s ease-in-out",
                  color: item?.text === "Logout" ? "red" : "black",
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </Popover>
  );
}
