import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { LocalOffer, Menu } from "@mui/icons-material";
import TicketDefaultField from "../InputFields/TicketDefault";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
import * as Yup from "yup";
import {
  createContentPageAction,
  updateContentPageAction,
} from "../../Store/Actions/Tickets";
import { registrationEventId } from "../../Constants/index";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateContentDialog({ data, open, close }) {
  const [loading, setLoading] = useState();
  const [formError, setFormError] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string()
      .max(35, "Description must be at most 35 characters")
      .required("Description is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
    capacity: Yup.number()
      .min(0, "Capacity must be at least 0")
      .max(10000, "Capacity cannot exceed 10000")
      .required("Capacity is required"),
    oldPrice: Yup.number()
      .positive("Old Price must be a positive number")
      .required("Old Price is required"),
    price: Yup.number()
      .positive("Price must be a positive number")
      .required("Price is required"),
    itemsList: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Item name is required"),
        value: Yup.number().required("Item value is required"),
      })
    ),
    location: Yup.string().required("Location is required"),
    detailedLocation: Yup.string().required("Detailed Location is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name,
      details: data?.details,
    },
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (loading) return;
        setLoading(true);
        setFormError("");

        // const formData = new FormData();
        // formData.append(`name`, values.name);
        // formData.append(`details`, values.details);
        // formData.append("id", data.id);
        const content = {
          name: values.name,
          details: values.details,
          id: data.id,
        };

        const response = await dispatch(updateContentPageAction(content));

        if (response?.status !== 200) {
          setFormError(response);
          return setLoading(false);
        }
        setLoading(false);

        return close();
      } catch (error) {
        setFormError(error);
        setLoading(false);
      }
      setLoading(false);
    },
  });

  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px",
          bgcolor: "#F4F5F7",
          maxHeight: "100%",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <img src="./images/dialogBg.png" alt="" style={{ width: "100%" }} />
        <img
          src="./images/dialogVectors.png"
          alt=""
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#FFF",
          }}
        >
          <span style={{ color: "#25AAE1" }}>Edit</span> Content Page
        </Typography>
      </Box>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
      >
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TicketDefaultField
                name={"name"}
                icon={<LocalOffer sx={{ transform: "rotate(90deg)" }} />}
                placeholder={"Enter Page Name"}
                label={"Page Name"}
                formik={formik}
              />
            </Grid>

            <Grid item xs={12}>
              <TicketDefaultField
                name={"details"}
                icon={<Menu />}
                placeholder={"Enter Page Details"}
                label={"Page Details"}
                formik={formik}
              />
            </Grid>

            {formError && (
              <Grid item xs={12}>
                <FormHelperText
                  sx={{
                    color: "red",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {formError}
                </FormHelperText>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "stretch",
                  gap: "1em",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={close}
                  sx={{ p: "0.5em 3em" }}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  sx={{ p: "0.5em 3em" }}
                >
                  Edit Content Page
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
