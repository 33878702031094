import { Box, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";

export default function LineChartComponent({ title, data }) {
  const dates = data?.map((item) => item?.Date);

  return (
    <Box
      sx={{
        boxShadow: "0px 4px 18px rgba(216, 216, 216, 0.6)",
        width: "100%",
      }}
    >
      <Box sx={{ bgcolor: "#FAFAFA", p: "0.5em 1em", borderRadius: "5px" }}>
        <Typography variant="subtitle1" sx={{ color: "#081531" }}>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: "#FFF",
          p: "1em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          minWidth: "200px",
          minHeight: "150px",
        }}
      >
        <LineChart
          width={500}
          height={300}
          series={[{ data: [4000, 3000], label: "uv" }]}
          xAxis={[
            {
              scaleType: "point",
              data: dates,
            },
          ]}
        />
      </Box>
    </Box>
  );
}
